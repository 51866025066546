import React from 'react';
import { Provider } from 'react-redux';
import { createStore as reduxCreateStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from '.';

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const windowGlobal = typeof window !== 'undefined' && window

const devtools =
  process.env.NODE_ENV === 'development' && windowGlobal.devToolsExtension
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f;

const configureStoreProd = initialState => {
  return reduxCreateStore(
    persistedReducer,
    initialState,
    compose(
      applyMiddleware(
        thunk,
      ),
      devtools
    )
  );
}

const createStore = () => configureStoreProd();

const store = createStore();
const persistor = persistStore(store)

export default ({ element }) => (
  <Provider store={store}>{element}</Provider>
);