import * as types from './types';
import {combineReducers} from 'redux';
import {omit as _omit} from 'lodash/object';

const items = (state = {}, {type, payload}) => {
  switch (type) {
    case types.ADD_ITEM: {
      const {id} = payload;

      if(state[id] === undefined){
        const item = {
          item: payload,
          count: 1
        }
        return {...state,[id]: item}
      } else {
        const item = state[id];
        item.count++;
        return {...state,[id]: item}
      }
    }
    case types.SET_ITEM_COUNT: {
      const {itemId, count} = payload;
      if(state[itemId] === undefined){
        return state;
      }
      const item = state[itemId];
      item.count = count;
      if(count <= 0){
        return _omit(state,[itemId]);
      } else {
        return { ...state, [itemId]: item }
      }
    }
    case types.PLUS_ITEM_COUNT: {
      const itemId = payload;
      if(state[itemId] === undefined){
        return state;
      }
      const item = state[itemId];
      item.count++;
      return {...state,[itemId]: item}
    }
    case types.MINUS_ITEM_COUNT: {
      const itemId = payload;
      if(state[itemId] === undefined){
        return state;
      }
      const item = state[itemId];
      item.count--;
      if(item.count <= 0){
        return _omit(state,[itemId]);
      } else {
        return { ...state, [itemId]: item }
      }
    }
    case types.RESET_BASKET: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({items});
