// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-basket-js": () => import("./../../../src/pages/basket.js" /* webpackChunkName: "component---src-pages-basket-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-payment-failure-js": () => import("./../../../src/pages/paymentFailure.js" /* webpackChunkName: "component---src-pages-payment-failure-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/paymentSuccess.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-news-news-js": () => import("./../../../src/templates/news/news.js" /* webpackChunkName: "component---src-templates-news-news-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

