import {createSelector} from 'reselect';
import {values as _values, mapValues as _mapValues} from 'lodash/object';

const selectBasketItemsState = state => state.basket.items;

export const getItems = createSelector(
  [selectBasketItemsState],
  (items) => {
    return _values(items);
  }
);

export const getTotalItems = createSelector(
  [selectBasketItemsState],
  (items) => {
    const data = _values(items);
    let total = 0;

    data.forEach(element => total+=element.count);

    return total;
  }
);

export const getTotalItemsValue = createSelector(
  [selectBasketItemsState],
  (items) => {
    const data = _values(items);
    let total = 0;

    data.forEach(element => {
      let price = Number(element.item.price.replace("£", ""))
      total+=(element.count * price)
    });
    return total;
  }
);

export const getItemsIdAndCount = createSelector(
  [selectBasketItemsState],
  (items) => {
    return _mapValues(items, item => item.count);
  }
);


export const getPaymentData = createSelector(
  [selectBasketItemsState],
  (items) => {
    const data = _values(items).map(item => ({
      id: item.item.id,
      count: item.count,
    }));

    return {products: data}
  }
);

export const getTotalValue = createSelector(
  [selectBasketItemsState],
  (items) => {
    let data = _values(items)
      .map(item => (
        item.count * parseFloat(item.item.rawPrice)
      ));

    if (data.length >0) {
      data = data.reduce((x, y) => x + y);
    } else {
      data = 0;
    }

    return data.toFixed(2);
  }
);
